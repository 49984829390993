<template>
  <div class="container" ref="homeContainer">
    <section class="hero" id="hero-section">
      <div class="hero__headline">
        <h1>{{ $t("joinSpinnovLbl") }}</h1>
      </div>
    </section>
    <div class="hero__animation">
      <Lines />
    </div>
    <section class="hero2" id="hero-section-2">
      <div class="hero2__headline_2">
        <h1>{{ $t("jobsWeAreCompanyLbl") }}</h1>
      </div>
      <div class="hero2__headline_applyNow">
        <h1>{{ $t("jobsPartOfUsLbl") }}</h1>
      </div>
    </section>
    <section class="jobs">
      <div class="jobs__headline">
        <h1>{{ $t("currentOffersLbl") }}</h1>
      </div>
      <div class="containerCards">
        <div
          v-for="job in this.jobs"
          :key="job.id"
          class="card"
          @click="goToJob(job.id)"
        >
          <span class="cardSpan"></span>
          <div class="content">
            <h3 class="cardh3">{{ job.title }}</h3>
            <p class="cardP">
              {{ job.hours }}
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="formSection">
      <div class="formSection__headline_form">
        <div class="formSection__headline_likeUs">
          <h1>{{ $t("doYouLikeUsLbl") }}</h1>
        </div>
        <div class="formSection__headline_Team">
          <h1>{{ $t("joinOurTeamLbl") }}</h1>
        </div>
        <form class="vue-form" @submit.prevent="submit">
          <fieldset class="fieldset">
            <div>
              <label class="label" for="name">{{
                $t("yourNameLblForm")
              }}</label>
              <input
                type="text"
                name="name"
                id="name"
                required=""
                v-model="name"
              />
            </div>
            <div>
              <label
                class="label"
                for="email"
                :class="{ emailLblErrorClass: !email.valid }"
                >{{ $t("yourEmailAddressFormLbl") }}</label
              >
              <input
                type="email"
                name="email"
                id="email"
                required=""
                :class="{ email, error: !email.valid }"
                v-model="email.value"
              />
            </div>

            <div style="margin-bottom:17px">
              <label class="label" for="textarea">{{
                $t("textArealblForm")
              }}</label>
              <textarea
                class="message"
                name="textarea"
                id="textarea"
                required=""
                v-model="message.text"
                :maxlength="message.maxlength"
              ></textarea>
              <span class="counter"
                >{{ message.text.length }} / {{ message.maxlength }}</span
              >
            </div>
            <div style="margin-top:0px;margin-bottom:0px" v-show="!email.valid">
              <p class="weNeedYourEmail">
                {{ $t("weNeedYourEmailAddrToLbl") }}
              </p>
            </div>
            <div style="margin-top:0px;margin-bottom:0px" v-show="err">
              <p class="weNeedYourEmail">
                {{ $t("errSubmittingContact") }}
              </p>
            </div>
            <vue-recaptcha
              ref="recaptcha"
              @verify="onCaptchaVerified"
              @expired="onCaptchaExpired"
              size="invisible"
              sitekey="6Lej2owaAAAAALmhhOl0ctOAOkzU82v3vv1SQTNS"
            >
            </vue-recaptcha>
            <div style="margin-top:17px">
              <input
                type="submit"
                :value="submitted ? $t('thankYouFor') : $t('sendMassageBtn')"
                :disabled="submitted"
                :class="submitted ? 'submittedClass' : ''"
              />
            </div>
          </fieldset>
        </form>
      </div>
    </section>
    <div class="footer-section">
      <Footer />
    </div>
  </div>
</template>

<script>
import Lines from "../components/Lines";
import Footer from "../components/Footer";
import { jobs } from "../variables/jobs.js";
var emailRegExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
import axios from "axios";
import VueRecaptcha from "vue-recaptcha";
import EventBus from "../event-bus";

export default {
  data() {
    return {
      name: "",
      email: {
        value: "",
        valid: true,
      },
      message: {
        text: ``,
        maxlength: 255,
      },
      submitted: false,
      jobs: jobs,
      err: false,
    };
  },
  watch: {
    // watching nested property
    "email.value": function(value) {
      this.validate("email", value);
    },
  },
  methods: {
    onScrollHandler(event) {
      if (this.resizedSmallerScreen) {
        window.scrollTo(0, 1);
      }
      let x = (event.target.scrollTop / 10) % Math.PI;
      EventBus.$emit("pageScrolled", { theta: x });
    },
    submit() {
      this.$refs.recaptcha.execute();
    },
    async onCaptchaVerified(recaptchaToken) {
      this.$refs.recaptcha.reset();
      // ! handling CV file format
      try {
        await axios.post(`https://api.spinnov.com/api/contact`, {
          name: this.name,
          email: this.email.value,
          message: this.message.text,
          recaptchaToken: recaptchaToken,
        });
        this.submitted = true;
      } catch (e) {
        this.err = true;

        this.submitted = false;
      }
    },
    onCaptchaExpired: function() {
      this.$refs.recaptcha.reset();
    },
    goToJob(ff) {
      this.$router.replace(`/jobs/${ff}`);
    },
    // validate by type and value
    validate(type, value) {
      if (type === "email") {
        this.email.valid = this.isEmail(value) ? true : false;
      }
    },
    // check for valid email adress
    isEmail(value) {
      return emailRegExp.test(value);
    },
  },
  components: {
    Lines,
    Footer,
    "vue-recaptcha": VueRecaptcha,
  },
  mounted() {
    if (this.$refs.homeContainer) {
      this.$refs.homeContainer.addEventListener("scroll", this.onScrollHandler);
    }
  },
  created() {
    let ckeditor = document.createElement("script");
    ckeditor.setAttribute(
      "src",
      "https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit"
    );
    document.head.appendChild(ckeditor);
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/_components.scss";
@import "../scss/_utilities.scss";
@import "../scss/_mixins.scss";

.container {
  height: calc(100vh - 115.2px);
  overflow-y: scroll;
  overflow-x: hidden;

  @include respond(laptop) {
    height: calc(100vh - 108px);
  }
  @include respond(small-laptop) {
    height: calc(100vh - 101.73px);
  }
  @include respond(tablet-land) {
    height: calc(100vh - 96px);
  }

  @include respond(tablet-port) {
    height: calc(100vh - 72px);
  }
}
.hero {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 5rem 15rem 0 15rem;

  @include respond(laptop) {
    margin: 4rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 3rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 1rem 8rem 0 8rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    margin: 4rem 3rem 0 3rem;
  }
  &__headline_2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 50%;
    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 2.4rem;
      font-weight: 400;
      opacity: 0.6;
      text-align: left;

      @include respond(desktop) {
        font-size: 2.4rem;
      }

      @include respond(laptop) {
        font-size: 2.2rem;
      }

      @include respond(small-laptop) {
        font-size: 2.1rem;
      }

      @include respond(tablet-land) {
        font-size: 2rem;
      }

      @include respond(phone-land) {
        font-size: 2rem;
      }
    }
  }

  &__headline {
    & > h1 {
      font-family: "Raleway";
      font-size: 8rem;
      font-weight: bold;
      text-align: center;
      @include respond(desktop) {
        font-size: 8rem;
      }

      @include respond(laptop) {
        font-size: 7rem;
      }

      @include respond(small-laptop) {
        font-size: 6rem;
      }

      @include respond(tablet-land) {
        font-size: 5rem;
      }

      @include respond(phone-land) {
        font-size: 4rem;
      }
    }
  }

  &__headline_applyNow {
    margin: 0 auto;
    width: 50%;

    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 3.2rem;
      font-weight: bold;
      text-align: left;
      color: $color-gray-4;

      @include respond(desktop) {
        font-size: 3.2rem;
      }

      @include respond(laptop) {
        font-size: 3rem;
      }

      @include respond(small-laptop) {
        font-size: 2.8rem;
      }

      @include respond(tablet-land) {
        font-size: 2.6rem;
      }

      @include respond(phone-land) {
        font-size: 2.6rem;
      }
    }
  }

  &__text {
    margin-bottom: -5rem;
    margin-left: -10rem;

    @include respond(laptop) {
      margin-left: -9rem;
    }

    @include respond(small-laptop) {
      margin-left: -12rem;
    }

    @include respond(tablet-land) {
      margin-left: -14rem;
    }

    @include respond(tablet-port) {
      margin-left: 0;
      margin-top: 3rem;
    }

    & > p {
      opacity: 0.6;
      font-family: "Open Sans";
      font-size: 2.4rem;
      line-height: 1.42;

      @include respond(desktop) {
        font-size: 2.3rem;
      }

      @include respond(laptop) {
        font-size: 2.1rem;
      }

      @include respond(small-laptop) {
        font-size: 2rem;
      }

      @include respond(tablet-land) {
        font-size: 1.8rem;
      }

      @include respond(phone-land) {
        font-size: 1.6rem;
      }
    }
  }

  &__btn-container {
    margin-left: auto;
    margin-right: -15.5rem;
    transform: rotate(-90deg);

    @include respond(laptop) {
      margin-right: -12.5;
    }

    @include respond(phone-land) {
      margin-right: -10rem;
      margin-top: 15rem;
    }
  }

  &__animation {
    margin-top: 5rem;

    @include respond(desktop) {
      margin-top: 4rem;
    }

    @include respond(laptop) {
      margin-top: 3rem;
    }

    @include respond(small-laptop) {
      margin-top: 2rem;
    }

    @include respond(tablet-land) {
      margin-top: 1rem;
    }

    @include respond(phone-land) {
    }
  }
}
.hero2 {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 5rem 15rem 0 15rem;

  @include respond(laptop) {
    margin: 4rem 12rem 0 12rem;
  }

  @include respond(small-laptop) {
    margin: 3rem 10rem 0 10rem;
  }

  @include respond(tablet-land) {
    margin: 1rem 8rem 0 8rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  @include respond(phone-land) {
    position: relative;
    margin: 1rem 3rem 0 3rem;
  }
  &__headline_2 {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem;
    width: 50%;
    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 2.4rem;
      font-weight: 400;
      opacity: 0.6;
      text-align: left;

      @include respond(desktop) {
        font-size: 2.4rem;
      }

      @include respond(laptop) {
        font-size: 2.2rem;
      }

      @include respond(small-laptop) {
        font-size: 2.1rem;
      }

      @include respond(tablet-land) {
        font-size: 2rem;
      }

      @include respond(phone-land) {
        font-size: 2rem;
      }
    }
  }

  &__headline_applyNow {
    margin: 0 auto;
    width: 50%;

    @include respond(desktop) {
      width: 50%;
    }

    @include respond(laptop) {
      width: 60%;
    }

    @include respond(small-laptop) {
      width: 65%;
    }

    @include respond(tablet-land) {
      width: 65%;
    }

    @include respond(phone-land) {
      width: 85%;
    }
    & > h1 {
      font-family: "Open Sans";
      font-size: 3.2rem;
      font-weight: bold;
      text-align: left;
      color: $color-gray-4;

      @include respond(desktop) {
        font-size: 3.2rem;
      }

      @include respond(laptop) {
        font-size: 3rem;
      }

      @include respond(small-laptop) {
        font-size: 2.8rem;
      }

      @include respond(tablet-land) {
        font-size: 2.6rem;
      }

      @include respond(phone-land) {
        font-size: 2.6rem;
      }
    }
  }
}
.formSection {
  display: block;
  align-items: center;
  justify-content: center;
  margin: 30rem 15rem 15rem 15rem;

  @include respond(laptop) {
    margin: 25rem 12rem 15rem 12rem;
  }

  @include respond(small-laptop) {
    margin: 20rem 10rem 15rem 10rem;
  }

  @include respond(tablet-land) {
    margin: 15rem 0rem 10rem 0rem;
  }
  @include respond(phone-land) {
    margin: 7rem 0rem 8rem 0rem;
  }

  @include respond(tablet-port) {
    flex-direction: column;
    align-items: flex-start;
  }

  &__headline_likeUs {
    & > h1 {
      font-family: "Open Sans";
      font-size: 8rem;
      font-weight: bold;
      text-align: center;

      @include respond(desktop) {
        font-size: 8rem;
      }

      @include respond(laptop) {
        font-size: 8rem;
      }

      @include respond(small-laptop) {
        font-size: 8rem;
      }

      @include respond(tablet-land) {
        font-size: 7rem;
      }

      @include respond(phone-land) {
        font-size: 4.5rem;
      }
    }
  }
  &__headline_Team {
    & > h1 {
      font-family: "Open Sans";
      font-size: 6.4rem;
      font-weight: 600;
      text-align: center;

      @include respond(desktop) {
        font-size: 6.4rem;
      }

      @include respond(laptop) {
        font-size: 6.4rem;
      }

      @include respond(small-laptop) {
        font-size: 5.2rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }

  &__headline_form {
    & > h1 {
      font-family: "Open Sans";
      font-size: 4.8rem;
      font-weight: bold;
      text-align: center;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 4.8rem;
      }

      @include respond(laptop) {
        font-size: 4.5rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }
}
.footer-section {
  scroll-snap-align: center;
}

.vue-form {
  font-size: 16px;
  padding: 0px 0px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  width: 65%;
  @include respond(desktop) {
    font-size: 2rem;
    width: 65%;
  }

  @include respond(laptop) {
    font-size: 2rem;
    width: 65%;
  }

  @include respond(small-laptop) {
    font-size: 2rem;
    width: 65%;
  }

  @include respond(tablet-land) {
    font-size: 2rem;
    width: 75%;
  }

  @include respond(phone-land) {
    margin-top: 0rem;
    font-size: 2rem;
    width: 85%;
  }
}
.vue-form fieldset {
  margin: 24px 0 0 0;
}
.vue-form legend {
  padding-bottom: 10px;
}
.vue-form div {
  position: relative;
  margin: 20px 0;
}
.vue-form h4,
.vue-form .label {
  font-family: "Raleway";
  font-size: 3.2rem;
  font-weight: bold;
  color: #000000;
  opacity: 0.8;
  margin-bottom: 10px;

  @include respond(desktop) {
    font-size: 3rem;
  }

  @include respond(laptop) {
    font-size: 2rem;
  }

  @include respond(small-laptop) {
    font-size: 2rem;
  }

  @include respond(tablet-land) {
    font-size: 2rem;
  }

  @include respond(phone-land) {
    font-size: 2rem;
  }
}
.vue-form .label {
  display: block;
}
.vue-form input,
.vue-form textarea,
.vue-form label {
  color: #30492d;
}
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea,
.vue-form legend {
  display: block;
  width: 100%;
  appearance: none;
}
.vue-form input[type="text"],
.vue-form input[type="email"],
.vue-form textarea {
  padding: 12px;

  font-family: "Open Sans";
  font-size: 2.5rem;
  border: none;
  background-color: #f0f0f0;

  @include respond(desktop) {
    font-size: 2.5rem;
  }

  @include respond(laptop) {
    font-size: 2.5rem;
  }

  @include respond(small-laptop) {
    font-size: 2.3rem;
  }

  @include respond(tablet-land) {
    font-size: 2rem;
  }

  @include respond(phone-land) {
    font-size: 1.8rem;
  }
}
.vue-form input[type="text"]:focus,
.vue-form input[type="email"]:focus,
.vue-form textarea:focus {
  outline: none;
}
.vue-form textarea {
  min-height: 220px;
  resize: none;
  overflow: auto;

  @include respond(phone-land) {
    min-height: 18rem;
  }
}
.vue-form input[type="submit"] {
  border: none;
  background: #4e3ee0;
  padding: 20px 20px;
  color: #ffffff;
  font-weight: bold;
  float: right;
  cursor: pointer;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  appearance: none;
  width: 100%;
}
.no-touch .vue-form input[type="submit"]:hover {
  background: #42a2e1;
}
.vue-form input[type="submit"]:active {
  transform: scale(0.9);
}
.vue-form .error-message {
  height: 35px;
  margin: 0px;
}
.vue-form .error-message p {
  background: #e94b35;
  color: #ffffff;
  font-size: 1.4rem;
  text-align: center;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 16px;
}
.vue-form .error {
  border-color: #e94b35 !important;
}
.vue-form .counter {
  background-color: #ecf0f1;
  position: absolute;
  right: 0px;
  top: 0px;
  font-size: 10px;
  padding: 4px;
}
.fieldset {
  border: 0px;
}
.weNeedYourEmail {
  font-family: "Open Sans";
  font-size: 2rem;
  color: #e73252;
  opacity: 0.8;

  @include respond(desktop) {
    font-size: 2rem;
  }

  @include respond(laptop) {
    font-size: 1.8rem;
  }

  @include respond(small-laptop) {
    font-size: 1.8rem;
  }

  @include respond(tablet-land) {
    font-size: 1.6rem;
  }

  @include respond(phone-land) {
    font-size: 1.6rem;
  }
}

.emailLblErrorClass {
  color: #e73252 !important;
}

.jobs {
  display: block;
  align-items: center;
  justify-content: center;
  margin-top: 30rem;
  @include respond(desktop) {
    margin-top: 30rem;
  }

  @include respond(laptop) {
    margin-top: 25rem;
  }

  @include respond(small-laptop) {
    margin-top: 20rem;
  }

  @include respond(tablet-land) {
    margin-top: 20rem;
  }

  @include respond(phone-land) {
    margin-top: 15rem;
  }
  &__headline {
    & > h1 {
      font-family: "Raleway";
      font-size: 4.8rem;
      font-weight: 600;
      text-align: center;
      opacity: 0.8;

      @include respond(desktop) {
        font-size: 4.8rem;
      }

      @include respond(laptop) {
        font-size: 4.5rem;
      }

      @include respond(small-laptop) {
        font-size: 4rem;
      }

      @include respond(tablet-land) {
        font-size: 4rem;
      }

      @include respond(phone-land) {
        font-size: 3.5rem;
      }
    }
  }
}
.containerCards {
  min-height: 50rem;
  width: 100vw;
  padding: 40px 0;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.card {
  cursor: pointer;
  position: relative;
  width: 40rem;
  height: 25rem;
  margin: 10px 15px;
  background-image: url("../assets/blue_back.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  display: block;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  /*-webkit-box-reflect: below -5px linear-gradient(to top, currentColor, transparent, transparent, transparent, transparent, transparent, transparent); */
  @include respond(desktop) {
    width: 40rem;
    height: 25rem;
    margin: 1rem 1.5rem;
  }

  @include respond(laptop) {
    width: 40rem;
    height: 25rem;
    margin: 1rem 1.5rem;
  }

  @include respond(small-laptop) {
    width: 40rem;
    height: 25rem;
    margin: 1rem 1.5rem;
  }

  @include respond(tablet-land) {
    width: 35rem;
    height: 20rem;
    margin: 1rem 1.5rem;
  }

  @include respond(phone-land) {
    width: 30rem;
    height: 18rem;
    margin: 1rem 1.5rem;
  }
  @include respond(phone) {
    width: 30rem;
    height: 18rem;
    margin: 1rem 1.5rem;
  }
}
.card:hover {
  transform: translateY(-7px);
}
.card::before,
.card::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
}

.cardSpan {
  position: absolute;
  left: 4px;
  z-index: 10;
}
.cardSpan::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 50%;
  background-color: #4e3ee0;
  pointer-events: none;
}

.content {
  z-index: 100;
  padding: 5rem 3rem 5rem 3rem;
  @include respond(desktop) {
    padding: 4rem 3rem 5rem 3rem;
  }

  @include respond(laptop) {
    padding: 3rem 3rem 3rem 3rem;
  }

  @include respond(small-laptop) {
    padding: 3rem 3rem 3rem 3rem;
  }

  @include respond(tablet-land) {
    padding: 2rem 2rem 2rem 2rem;
  }

  @include respond(phone-land) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }
  @include respond(phone) {
    padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  }
}

.cardh3 {
  font-family: "Raleway";
  font-weight: bold;
  color: rgb(255, 255, 255);
  margin-bottom: 1rem;
  font-size: 4rem;
  text-transform: capitalize;
  text-align: left;

  @include respond(desktop) {
    margin-bottom: 1rem;
    font-size: 4rem;
  }

  @include respond(laptop) {
    margin-bottom: 1rem;
    font-size: 3.8rem;
  }

  @include respond(small-laptop) {
    margin-bottom: 1rem;
    font-size: 3.6rem;
  }

  @include respond(tablet-land) {
    margin-bottom: 1rem;
    font-size: 3.2rem;
  }

  @include respond(phone-land) {
    margin-bottom: 1rem;
    font-size: 2.8rem;
  }
  @include respond(phone) {
    margin-bottom: 1rem;
    font-size: 2.7rem;
  }
}

.cardP {
  position: absolute;
  bottom: 0;
  font-family: "Open Sans";
  color: rgb(255, 255, 255);
  line-height: 1.5;
  text-align: justify;
  margin-bottom: 15px;
  font-size: 2.4rem;

  @include respond(desktop) {
    font-size: 2.4rem;
  }

  @include respond(laptop) {
    font-size: 2.4rem;
  }

  @include respond(small-laptop) {
    font-size: 2.2rem;
  }

  @include respond(tablet-land) {
    font-size: 2.1rem;
  }

  @include respond(phone-land) {
    font-size: 2.1rem;
  }
  @include respond(phone) {
    font-size: 2rem;
  }
}
.submittedClass {
  outline: none;
  background: #4bb543 !important;
  cursor: not-allowed !important;
}
</style>
